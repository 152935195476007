import { Environment } from "src/app/types";

export const environment: Environment = {
  production: false,
  // firebase: {
  //   apiKey: "AIzaSyCKxt0dAVGWyKybcsHQ76hXTfyz4uaf7HA",
  //   authDomain: "koutavi.firebaseapp.com",
  //   databaseURL: "https://koutavi.firebaseio.com",
  //   projectId: "koutavi",
  //   storageBucket: "koutavi.appspot.com",
  //   messagingSenderId: "813715320930",
  //   appId: "1:813715320930:web:65c97570bbdf693ce9213b",
  //   measurementId: "G-QHL6BNZLR6",
  // },
  // algolia: {
  //   key: "9d64907416677ba867740567b52fe0f4",
  //   id: "GZZ7IHE2S0",
  // },
  // Use prod firebase/algolia since scrapers don't run on dev.
  firebase: {
    apiKey: "AIzaSyB7CFEyTFkaPM2PfjDELHuPDB_8uaWkrWM",
    authDomain: "taste-app-254506.firebaseapp.com",
    databaseURL: "https://taste-app-254506.firebaseio.com",
    projectId: "taste-app-254506",
    storageBucket: "taste-app-254506.appspot.com",
    messagingSenderId: "810018090498",
    appId: "1:810018090498:web:2bb1647b5c5755ae90811b",
    measurementId: "G-L10CHRRTYQ",
  },
  algolia: {
    key: "2c507656eb1f42ea5658259adc96e772",
    id: "P31QNDVVZR",
  },
  searchInstagram: false,
  showAllUsers: false,
};
