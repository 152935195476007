<div *ngIf="!(clusters | async)" class="spinner-container">
  <mat-spinner class="spinner" [diameter]="50"></mat-spinner>
</div>
<div *ngIf="user | async as user" class="wrapper">
  <mgl-map (mousedown)="mapMouseDown($event)" (click)="mapClick($event)" [pitchWithRotate]="false" [dragRotate]="false"
    [touchZoomRotate]="true" [center]="[user.location.longitude, user.location.latitude]"
    [style]="'mapbox://styles/jackdreilly/ck6waawr60u4e1io8m0mejn5t'" [zoom]="initialZoom" (load)="mapLoad($event)"
    [logoPosition]="'bottom-right'" (idle)="updateGen('idle')" (zoomEvt)="zoomChange($event)"
    (touchStart)="showFilters = false">
    <mgl-control mglNavigation [showZoom]="true" [showCompass]="false" position="bottom-right"></mgl-control>
    <mgl-marker *ngFor="let cluster of clusters | async" [lngLat]="cluster.parent.location">
      <div (mousedown)="mapMouseDown($event)" (click)="mapClick($event)">
        <div [ngClass]="{
            marker: true,
            active: cluster.parent.active,
            inactive:
              !cluster.parent.active &&
              (cluster.parent.inactive || user.seeThrough)
          }">
          <div class="inner children">
            <div *ngFor="let marker of cluster.fanned; let i = index" class="child" [ngClass]="{
                center: cluster.fanned.length === 1,
                left: i === 0 && cluster.fanned.length === 2,
                right: i === 1 && cluster.fanned.length === 2
              }" (click)="markerTap($event, marker)">
              <img (error)="failedMarker(marker)" [ngClass]="{
                  'not-food': !marker.is_food
                }" class="avatar" [src]="resolve(marker.post.photo_url)" />
            </div>
          </div>
          <div class="inner photo" (click)="clusterTap($event, cluster)">
            <img (error)="failedMarker(cluster.parent)" [ngClass]="{
                'not-food': !cluster.parent.is_food
              }" class="avatar" [src]="
                resolve(cluster.parent.post.photo_url, cluster.parent.active)
              " />
          </div>
          <div [routerLink]="userPhotoRoute(cluster.parent.post)" class="inner user"
            (click)="userPhotoTap($event, cluster.parent)" [class.has-user]="user.exists">
            <img class="avatar" [src]="
                resolve(
                  cluster.parent.post.profile_pic_url,
                  cluster.parent.active
                )
              " />
          </div>
          <div class="inner name">
            {{ cluster.parent.post.restaurant.name }}
            <div [routerLink]="userPhotoRoute(cluster.parent.post)" (click)="userPhotoTap($event, cluster.parent)"
              class="username" *ngIf="!user.exists && cluster.parent.post.user.name">
              {{ cluster.parent.post.user.name }}
            </div>
          </div>
          <div class="inner count" [class.has-no-children]="
              cluster.parent.active || cluster.children.length < 3
            ">
            +{{ cluster.children.length - cluster.fanned.length }}
            <span class="more">more</span>
          </div>
          <div class="inner score">
            {{ cluster.parent.post.num_insta_likes || cluster.parent.post.score }}{{ user.is_instagram_post ? "❤️" :  "⭐"}}
          </div>
        </div>
      </div>
    </mgl-marker>
  </mgl-map>
  <div id="filter-control">
    <mat-chip-list class="mat-chip-list-stacked">
      <mat-chip (click)="showFilters = !showFilters">
        {{ showFilters ? 'Hide' : 'Show' }} Filters
      </mat-chip>
    </mat-chip-list>
  </div>
  <div *ngIf="showFilters" id="map-filters">
    <mat-chip-list class="mat-chip-list-stacked">
      <mat-chip-list>
        <mat-chip *ngFor="let foodType of foodFilters"
          [class.chip-selected]="(getSelectedFilter() | async) === foodType" (click)="foodFilterClicked(foodType)">
          {{ foodType }}
        </mat-chip>
      </mat-chip-list>
    </mat-chip-list>
  </div>
  <div id="top-bar">
    <div id="top-row">
      <img (click)="goToMyLocation()" src="./assets/public/images/my_location.png"
        class="my-location btn btn-small btn-light" />
      <img (click)="fullScreenToggle()" src="./assets/public/images/fullscreen.png"
        class="my-location btn btn-small btn-light" />
      <app-user-search *ngIf="mapInstance" [map]="mapInstance" (selected)="selectedResult($event)"></app-user-search>
    </div>
    <div id="bottom-row">
      <div *ngIf="user.exists && !(activeMarker | async)" class="user-info info-panel" [class.expanded]="expanded">
        <div (click)="toggleExpand()" class="square">
          <img class="avatar" [src]="resolve(user.user.profile_pic_url)" />
        </div>
        <div *ngIf="expanded" class="details">
          <div (click)="goToLink('https://instagram.com/' + user.user.username)" class="username">
            {{ user.user.username }}
          </div>
          <a [ngStyle]="{ display: showAllUsers() ? 'unset' : 'none' }" (click)="clickAllFoodies()"
            [routerLink]="[user.taste ? '/t' : '/i', '']" class="btn btn-dark btn-sm">All Foodies</a>
          <a (click)="toggleExpand()" class="btn btn-light btn-sm">Collapse</a>
        </div>
      </div>
      <button style="margin: 5px;" *ngIf="flyerPosition | async as flyerPosition" class="fly-button btn btn-dark"
        (click)="goToFlyerPosition(flyerPosition)">
        Fly to {{ user.user.username }}'s posts
      </button>
    </div>
  </div>
  <div *ngIf="reviewPanelMarker | async as marker" class="review-detail info-panel">
    {{ marker.post.review.text }}
  </div>
  <div id="download-buttons">
    <button (click)="goToLink('https://trytaste.app')" class="btn btn-small btn-light" id="logo">
      <img src="./assets/public/images/taste_logo.png" />Taste
    </button>
    <div id="google-button">
      <div class="download-button">
        <a
          href="https://play.google.com/store/apps/details?id=app.trytaste.taste&utm_source=https%3A%2F%2Ftrytaste.app&utm_campaign=loading&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
          <img alt="Get it on Google Play"
            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" /></a>
      </div>
    </div>
    <div id="apple-button">
      <div class="download-button">
        <a href="https://apps.apple.com/us/app/taste-social/id1487666184?ls=1">
          <img alt="Get it on Apple App Store" src="./assets/public/images/appstore.png" /></a>
      </div>
    </div>
  </div>
</div>