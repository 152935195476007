import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { LngLatBoundsLike, LngLatLike, Map } from "mapbox-gl";
import { from, Observable, Subject } from "rxjs";
import { filter, map, startWith, switchMap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { fireUrl, Resolution } from "../photos";
import { InstagramService, InstagramUser } from "../instagram.service";

interface GeocodeResult {
  place_name: string;
  bbox: LngLatBoundsLike;
  center: LngLatLike;
}

class SearchResult {
  item: InstagramUser | GeocodeResult;
  isUser: boolean;
  description: string;
  keyword: string;
  place: GeocodeResult | null;
  user: InstagramUser | null;
  constructor(item: InstagramUser | GeocodeResult) {
    this.item = item;
    this.isUser = !!(item as InstagramUser).username;
    this.place = this.isUser ? null : (item as GeocodeResult);
    this.user = this.isUser ? (item as InstagramUser) : null;

    this.description = this.isUser
      ? `${this.user.username}: ${this.user.full_name}`
      : this.place.place_name;
    this.keyword = this.isUser ? this.user.username : this.place.place_name;
  }
}

const token =
  "pk.eyJ1IjoiamFja2RyZWlsbHkiLCJhIjoiY2s2d2E3MG5qMDlsdTNubXZteXMwc2loNSJ9.C5X0kx26B6QDZibVZau9YQ";

@Component({
  selector: "app-user-search",
  templateUrl: "./user-search.component.html",
  styleUrls: ["./user-search.component.scss"],
})
export class UserSearchComponent implements OnInit {
  private terms = new Subject<string>();
  @Output() selected = new EventEmitter<
    string | LngLatBoundsLike | LngLatLike
  >();
  @Input()
  map: Map;
  searchResults: Observable<SearchResult[]>;
  @ViewChild("attribution", { static: true }) attribution;
  constructor(private insta: InstagramService) {}
  keyword = "keyword";

  searchInstagram = environment.searchInstagram;

  picUrl(url: string): string {
    return fireUrl(url, Resolution.thumbnail);
  }

  ngOnInit() {
    this.searchResults = this.terms.pipe(
      filter((x) => x.length > 0),
      switchMap((t) =>
        this.placeResults(t).pipe(map((u) => u.map((u) => new SearchResult(u))))
      )
    );
  }

  placeResults(term: string): Observable<GeocodeResult[]> {
    return from(
      fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${term}.json?access_token=${token}&autocomplete=true`
      )
    ).pipe(
      switchMap((x) => x.json()),
      map((x) => x.features),
      startWith([])
    );
  }

  async selectEvent(searchResult: SearchResult) {
    if (searchResult.isUser) {
      this.selected.emit(searchResult.user.username);
      return;
    }
    this.selected.emit(searchResult.place.bbox || searchResult.place.center);
  }

  onChangeSearch(val: string) {
    this.terms.next(val);
  }
}
