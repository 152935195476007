import { environment } from "src/environments/environment";

export enum Resolution {
  thumbnail,
  medium,
  full,
}

const _regex = new RegExp("^(.*)/(\\d+(\\.\\d+)?)\\.(jpe?g|png)$");

export function fireUrl(url: string, resolution: Resolution): string {
  if (!url) {
    return null;
  }
  if (url.startsWith("http")) {
    return url;
  }
  return `https://storage.googleapis.com/${
    environment.firebase.storageBucket
  }/${pathForResolution(url, resolution)}`;
}

function pathForResolution(path: string, resolution: Resolution): string {
  if (resolution == Resolution.full || resolution == null) {
    return path;
  }
  const match = path.match(_regex);
  if (!match) {
    return null;
  }
  const size = resolution == Resolution.medium ? 600 : 200;
  return `${match[1]}/thumbnails/${match[2]}_${size}x${size}.${match[4]}`;
}
