import { Component, OnInit } from "@angular/core";

@Component({
  selector: "ng-autocomplete-lib",
  template: `
    <p>
      autocomplete-lib works!
    </p>
  `,
  styles: [],
})
export class AutocompleteLibComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
