import { Injectable } from "@angular/core";
import { AngularFireAnalytics } from "@angular/fire/analytics";
import { AngularFirestore } from "@angular/fire/firestore";
import SphericalMercator from "@mapbox/sphericalmercator";
import algoliasearch from "algoliasearch";
import { LngLatBounds } from "mapbox-gl";
import { combineLatest, Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { environment } from "./../environments/environment";
import { tasteCache } from "./cache";
import { InstagramPost } from "./taste.data";
import { getTiles, token } from "./tiling";
import { DiscoverItem, UserPostsStartingLocation } from "src/protos/firestore";
import { fireUrl, Resolution } from "./photos";
export { InstagramPost, InstagramUser } from "./taste.data";

interface InstagramUser {
  username: string;
  full_name: string;
  profile_pic_url: string;
  post_type: "instagram" | "taste";
}

const discoverItems = "discover_items";

const mercator = new SphericalMercator({});

@Injectable({
  providedIn: "root",
})
export class InstagramService {
  index: algoliasearch.Index;
  constructor(
    private firestore: AngularFirestore,
    private analytics: AngularFireAnalytics
  ) {
    this.analytics.updateConfig({ debug_mode: !environment.production });
    const client = algoliasearch(
      environment.algolia.id,
      environment.algolia.key
    );
    this.index = client.initIndex("discover");
  }

  async resolveDetails(username: string) {
    const docs = (
      await this.firestore
        .collection(discoverItems, (q) => q.where("user.name", "==", username))
        .get()
        .toPromise()
    ).docs;
    if (docs.length === 0) {
      return false;
    }
    return true;
  }

  getUserDetails(username: string): Observable<InstagramUser> {
    return this.firestore
      .collection<DiscoverItem>(discoverItems, (ref) => {
        let query: firebase.firestore.Query = ref;
        query = query.where("user.name", "==", username);
        query = query.limit(1);
        return query;
      })
      .valueChanges()
      .pipe(
        map((results) => results[0]),
        map(({ user }) => ({
          username: user.name,
          full_name: user.name,
          profile_pic_url: fireUrl(user.photo, Resolution.full),
          post_type: "taste",
        }))
      );
  }

  posts(
    username: string,
    bounds: LngLatBounds,
    zoom: number
  ): { cells: string[]; posts: Observable<InstagramPost[]> } {
    const [left, bottom] = mercator.forward([
      bounds.getWest(),
      bounds.getSouth(),
    ]);
    const [right, top] = mercator.forward([
      bounds.getEast(),
      bounds.getNorth(),
    ]);
    const cells = getTiles({ left, bottom, top, right }, zoom).map(token);
    return {
      cells,
      posts: combineLatest(
        cells.map((cell) => this.postsForCell(username, cell))
      ).pipe(map((c) => [].concat(...c))),
    };
  }

  userLatLng(
    username: string
  ): Observable<{ latitude: number; longitude: number }> {
    return tasteCache("userLatLng", username, () =>
      this.firestore
        .collection<UserPostsStartingLocation>(
          "user_posts_starting_locations",
          (ref) => {
            let query: firebase.firestore.Query = ref;
            return query.where("username", "==", username).limit(1);
          }
        )
        .valueChanges()
        .pipe(
          take(1),
          map((d) => (d.length > 0 ? d[0].location : null))
        )
    );
  }

  postsForCell(
    username: string,
    cell: string | null
  ): Observable<InstagramPost[]> {
    return this.firestore
      .collection<DiscoverItem>(discoverItems, (ref) => {
        let query: firebase.firestore.Query = ref;
        if (!!cell) {
          // query = query.orderBy("score", "desc");
          query = query.limit(20);
          query = query.where(`spatial_index.levels`, "array-contains", cell);
        }
        if (username.length > 0) {
          query = query.where("user.name", "==", username);
        }
        return query;
      })
      .valueChanges()
      .pipe(
        map((items) =>
          items.map((item) => ({
            ...item,
            username: item.user.name,
            full_name: item.user.name,
            profile_pic_url: fireUrl(item.user.photo, Resolution.full),
            pk: item.reference.path,
            text: item.review.text,
            photo_url: undefined,
            photo_urls: item.fire_photos.map((firePhoto) =>
              fireUrl(firePhoto.firebase_storage, Resolution.full)
            ),
            location_name: item.restaurant.name,
            post_type: item.is_instagram_post ? "instagram" : "taste",
          }))
        )
      );
  }
}
