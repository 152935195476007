<div class="ng-autocomplete">
  <ng-autocomplete
    [minQueryLength]="2"
    [debounceTime]="200"
    [placeHolder]="
      'Search Places...'
    "
    [data]="searchResults | async"
    [searchKeyword]="keyword"
    (selected)="selectEvent($event)"
    (inputChanged)="onChangeSearch($event)"
    [itemTemplate]="itemTemplate"
  >
  </ng-autocomplete>
</div>
<div #attribution></div>
<ng-template #itemTemplate let-item>
  <div class="search-row">
    <img
      [src]="
        picUrl(
          item.isUser
            ? item.user.profile_pic_url
            : 'https://lh3.googleusercontent.com/9tLfTpdILdHDAvGrRm7GdbjWdpbWSMOa0csoQ8pUba9tLP8tq7M4Quks1xuMQAVnAxVfryiDXRzZ-KDnkPv8Sm4g_YFom1ltQHjQ6Q'
        )
      "
    />
    <a innerHTML="{{ item.description }}"></a>
  </div>
</ng-template>
