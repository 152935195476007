const ProjExtent = {
  left: -20037508.342789244,
  right: 20037508.342789244,
  bottom: -20037508.342789244,
  top: 20037508.342789244,
};
// resolutions
const Resolutions = [
  156543.03392804097,
  78271.51696402048,
  39135.75848201024,
  19567.87924100512,
  9783.93962050256,
  4891.96981025128,
  2445.98490512564,
  1222.99245256282,
  611.49622628141,
  305.748113140705,
  152.8740565703525,
  76.43702828517625,
  38.21851414258813,
  19.109257071294063,
  9.554628535647032,
  4.777314267823516,
  2.388657133911758,
  1.194328566955879,
  0.5971642834779395,
  0.29858214173896974,
  0.14929107086948487,
  0.07464553543474244,
  0.03732276771737122,
  0.01866138385868561,
];

const tileSize = 256;

interface Extent {
  left: number;
  right: number;
  top: number;
  bottom: number;
}

interface Tile {
  X: number;
  Y: number;
  Z: number;
}

export function token(tile: Tile) {
  return [tile.X, tile.Y, tile.Z].join(",");
}

export function getTiles(extent: Extent, z: number): Tile[] {
  // coordinated in pixel
  const lx = Math.floor((extent.left - ProjExtent.left) / Resolutions[z]);
  const rx = Math.floor((extent.right - ProjExtent.left) / Resolutions[z]);
  const by = Math.floor((ProjExtent.top - extent.bottom) / Resolutions[z]);
  const ty = Math.floor((ProjExtent.top - extent.top) / Resolutions[z]);
  // tile numbers
  const lX = Math.floor(lx / tileSize);
  const rX = Math.floor(rx / tileSize);
  const bY = Math.floor(by / tileSize);
  const tY = Math.floor(ty / tileSize);
  // top left tile position of top-left tile with respect to window/div
  const topStart = tY * tileSize - ty;
  let top = topStart;
  let left = lX * tileSize - lx;
  const tiles = [];
  for (let i = lX; i <= rX; i++) {
    top = topStart;
    for (let j = tY; j <= bY; j++) {
      tiles.push({
        X: i,
        Y: j,
        Z: z,
      });
      top += tileSize;
    }
    left += tileSize;
  }
  return tiles;
}
