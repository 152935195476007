import { AgmCoreModule, GoogleMapsAPIWrapper } from "@agm/core";
import { NgModule } from "@angular/core";
import { AngularFireModule } from "@angular/fire";
import {
  AngularFireAnalyticsModule,
  UserTrackingService,
} from "@angular/fire/analytics";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { BrowserModule } from "@angular/platform-browser";
import { AgmOverlays } from "agm-overlays";
import { NgxMapboxGLModule } from "ngx-mapbox-gl";
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AutocompleteLibModule } from "./lib/autocomplete-lib.module";
import { TasteMapComponent } from "./taste-map/taste-map.component";
import { UserSearchComponent } from "./user-search/user-search.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatChipsModule } from "@angular/material/chips";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

const mapBoxToken =
  "pk.eyJ1IjoiamFja2RyZWlsbHkiLCJhIjoiY2s2d2E3MG5qMDlsdTNubXZteXMwc2loNSJ9.C5X0kx26B6QDZibVZau9YQ";

@NgModule({
  declarations: [AppComponent, TasteMapComponent, UserSearchComponent],
  imports: [
    AngularFireAnalyticsModule,
    NgxMapboxGLModule.withConfig({
      accessToken: mapBoxToken,
    }),
    AgmOverlays,
    AngularFireModule.initializeApp(environment.firebase, "Taste Map"),
    AngularFireStorageModule,
    AngularFirestoreModule.enablePersistence(),
    AppRoutingModule,
    BrowserModule,
    AutocompleteLibModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyCKxt0dAVGWyKybcsHQ76hXTfyz4uaf7HA",
      libraries: ["places"],
    }),
    BrowserAnimationsModule,
    MatChipsModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
  ],
  providers: [GoogleMapsAPIWrapper, UserTrackingService],
  bootstrap: [AppComponent],
})
export class AppModule {}
