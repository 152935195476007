const cache = new Map<string, Map<any, any>>();
export function tasteCache<K, V>(cacheKey: string, k: K, v: () => V) {
  if (!cache.has(cacheKey)) {
    cache.set(cacheKey, new Map());
  }
  const c = cache.get(cacheKey);
  if (c.has(k)) {
    return c.get(k);
  }
  const value = v();
  c.set(k, value);
  return value;
}
