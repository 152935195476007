import { LatLngLiteral } from "@agm/core";
import { Component, HostListener, OnInit } from "@angular/core";
import { AngularFireAnalytics } from "@angular/fire/analytics";
import { ActivatedRoute, Router } from "@angular/router";
import {
  LngLat,
  LngLatBounds,
  LngLatBoundsLike,
  Map as MapboxMap,
} from "mapbox-gl";
import * as R from "ramda";
import * as md5 from "md5";
import { combineLatest, Observable, of, Subject, BehaviorSubject } from "rxjs";
import { auditTime, map, pairwise, startWith, switchMap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import {
  InstagramPost,
  InstagramService,
  InstagramUser,
} from "../instagram.service";
import { fireUrl, Resolution } from "../photos";
import { LruCache } from "./lru";
import { mapStyle } from "./map-style";

const padding = { left: 45, right: 45, top: 45, bottom: 45 };
const isMobile = /Android|webOS|iPhone|iPad|iPod/i.test(navigator.userAgent);

const foodTypeRemap = {
  sandwich: "burgers_and_sandwiches",
  burgers_and_chicken_sandwiches: "burgers_and_sandwiches",
  latte: "coffee",
  macaron: "dessert",
  dim_sum: "dumpling",
  udon: "noodle",
  soup_dumpling: "dumpling",
  salmon: "fish",
  muffin: "pastry",
  croissant: "pastry",
  noodle_soup: "soup",
  scone: "pastry",
  cheesecake: "dessert",
  churro: "dessert",
  espresso: "coffee",
  eggs_benedict: "breakfast",
  hot_pot: "soup",
  egg: "breakfast",
  ice_cream: "dessert",
};

const foodFilters = {
  dessert: "Dessert",
  breakfast: "Breakfast",
  pizza: "Pizza",
  salad: "Salads",
  burgers_and_chicken_sandwiches: "Burgers/Sandwiches",
  seafood: "Seafood",
  soup: "Soup",
  sushi: "Sushi",
  taco: "Tacos",
  pasta: "Pasta",
  noodle: "Noodles",
  shrimp: "Shrimp",
  ramen: "Ramen",
  curry: "Curry",
  steak: "Steak",
  coffee: "Coffee",
  cocktail: "Cocktail",
  pastry: "Pastries",
  wine: "Wine",
  donut: "Donuts",
  cupcake: "Cupcakes",
  fried_chicken: "Fried Chicken",
  french_fries: "French Fries",
  fish: "Fish",
  dumpling: "Dumpling",
  chow_mein: "Chow Mein",
  pancake: "Pancake",
  pho: "Pho",
  waffle: "Waffles",
  nachos: "Nachos",
  burrito: "Burritos",
  fried_rice: "Fried Rice",
  lobster_roll: "Lobster Roll",
  shaved_ice: "Shaved Ice",
  cake: "Cakes",
};

interface Marker {
  post: InstagramPost;
  point: {
    x: number;
    y: number;
  };
  active: boolean;
  inactive: boolean;
  location: LatLngLiteral;
  is_food: boolean;
}

function postLocation(post: InstagramPost): LatLngLiteral {
  return { lat: post.location.latitude, lng: post.location.longitude };
}

function getFoodTypes(post: InstagramPost, photoIdx: number) {
  let foodTypes = [];
  for (const [foodTypeIndex, foodType] of post.review?.food_types?.entries() ??
    []) {
    if (post.review.food_types_photo_indices[foodTypeIndex] == photoIdx) {
      foodTypes.push(
        foodType in foodTypeRemap ? foodTypeRemap[foodType] : foodType
      );
    }
  }
  return foodTypes;
}

class Cluster {
  parent: Marker;
  children: Marker[] = [];
  fanned: Marker[] = [];
  location: LatLngLiteral;
}

interface MapState {
  zoom: number;
  bounds: LngLatBounds;
}

@Component({
  selector: "app-taste-map",
  templateUrl: "./taste-map.component.html",
  styleUrls: ["./taste-map.component.scss"],
})
export class TasteMapComponent implements OnInit {
  reviewPanelMarker: Observable<Marker>;
  cells: Observable<LatLngLiteral[][]>;
  flyerPosition: Observable<LatLngLiteral>;
  initialZoom = 13;
  showAllUsers(): boolean {
    return environment.showAllUsers;
  }

  constructor(
    private insta: InstagramService,
    private route: ActivatedRoute,
    private router: Router,
    private analytics: AngularFireAnalytics
  ) {}

  private get mapState(): MapState {
    if (!this.mapInstance) {
      return null;
    }
    const zoom = this.mapInstance.getZoom();
    const bounds = this.mapInstance.getBounds();
    return { zoom, bounds };
  }

  private get nextGeneration(): number {
    return this.gen++;
  }

  get boundsExpanded(): LngLatBounds | null {
    const bounds = this.mapInstance.getBounds();
    if (!bounds) {
      return null;
    }
    const sw = bounds.getSouthWest();
    const ne = bounds.getNorthEast();
    const center = bounds.getCenter();
    const expansionFactor = 1.0;
    const distLat = (center.lat - sw.lat) * expansionFactor;
    const distLng = ((center.lng - sw.lng + 360) % 360) * expansionFactor;
    bounds.extend(
      new LngLat(
        Math.max(-180, sw.lng - distLng),
        Math.max(-90, sw.lat - distLat)
      )
    );
    bounds.extend(
      new LngLat(
        Math.min(180, ne.lng + distLng),
        Math.min(90, ne.lat + distLat)
      )
    );
    return bounds;
  }

  foodFilters = Object.values(foodFilters);
  showFilters = !isMobile;
  private selectedFilterSubject = new BehaviorSubject<string>(null);

  getSelectedFilter(): Observable<string> {
    return this.selectedFilterSubject.asObservable();
  }

  mapInstance: MapboxMap;
  clusters: Observable<Cluster[]>;
  markerMap = new LruCache<Marker>(1200);
  private failedMarkers = new Set<string>();
  private downEvent: ClickEvent;
  private generation = new Subject<number>();
  private activeMarkerSubject = new Subject<Marker>();
  private boundsUpdateSubject = new Subject<LngLatBounds>();
  private boundsUpdate = this.boundsUpdateSubject.asObservable();
  activeMarker = this.activeMarkerSubject.asObservable().pipe(startWith(null));
  expanded = !isMobile;
  private gen = 0;
  user: Observable<{
    user: InstagramUser;
    exists: boolean;
    location: { latitude: number; longitude: number };
  }>;
  defaultZoom = 7;

  foodFilterClicked(foodType: string) {
    this.selectedFilterSubject.next(
      this.selectedFilterSubject.value == foodType ? null : foodType
    );
  }

  toggleExpand() {
    this.analytics.logEvent("imap-toggle-user-expanded");
    this.expanded = !this.expanded;
  }
  updateGen(arg) {
    const next = this.nextGeneration;
    if (this.generation) {
      this.generation.next(next);
    }
    if (this.boundsUpdateSubject) {
      if (this.mapInstance) {
        const expandedBounds = this.boundsExpanded;
        if (expandedBounds) {
          this.boundsUpdateSubject.next(expandedBounds);
        }
      }
    }
  }

  resolve(path: string, active: boolean = false): string {
    return fireUrl(path, active ? Resolution.medium : Resolution.medium);
  }

  selectedResult(result: any) {
    if (result.length > 0 && typeof result[0] === "number") {
      this.analytics.logEvent("imap-selected-place");
      if (result.length === 4) {
        this.mapInstance.fitBounds(result as LngLatBoundsLike, { padding });
      } else {
        this.mapInstance.flyTo({ center: result, zoom: 17, duration: 500 });
      }
      return;
    }
    if (!!(result as string).length) {
      const username = result as string;
      this.analytics.logEvent("imap-selected-username", { username });
      this.router.navigate(["/", username]);
      return;
    }
  }

  ngOnInit() {
    this.reviewPanelMarker = this.activeMarker.pipe(
      map((marker) =>
        marker && marker.post && !!marker.post.review.text ? marker : null
      )
    );
    const username$ = this.route.paramMap.pipe(
      map((m) => ({
        username: m.get("username") || "",
      })),
      switchMap(({ username }) => {
        const secretCode = md5(username).substring(0, 5);
        return this.route.queryParamMap.pipe(
          map((queryMap) => ({
            username,
            has_access:
              !environment.production ||
              (queryMap.get("secret_code") || "").startsWith(secretCode),
          }))
        );
      }),
      switchMap((m) => {
        return (!m.username
          ? of({ latitude: 37.7746129, longitude: -122.445392 })
          : this.insta.userLatLng(m.username)
        ).pipe(
          map((location) => ({
            ...m,
            location: location ?? {
              latitude: 37.7746129,
              longitude: -122.445392,
            },
          }))
        );
      })
    );
    const postCells = username$
      .pipe(
        switchMap(({ username, has_access }) =>
          this.boundsUpdate.pipe(
            auditTime(500),
            map((bounds) => ({ bounds, username, has_access }))
          )
        )
      )
      .pipe(
        switchMap(({ bounds, username, has_access }) => {
          if (!has_access) {
            return of({ posts: [], cells: [] });
          }
          const { posts, cells } = this.insta.posts(
            username,
            bounds,
            Math.floor(this.mapInstance.getZoom())
          );
          return posts.pipe(
            map(
              // tslint:disable-next-line: no-shadowed-variable
              (posts) => {
                const flattened: InstagramPost[] = [];
                for (const post of posts) {
                  for (const [idx, photoUrl] of post.photo_urls.entries()) {
                    flattened.push({
                      ...post,
                      photo_url: photoUrl,
                      review: {
                        ...post.review,
                        food_types: getFoodTypes(post, idx),
                      },
                    });
                  }
                }
                return {
                  posts: flattened,
                  cells,
                };
              }
            )
          );
        })
      );
    const markers$ = postCells.pipe(
      switchMap(({ posts }) =>
        this.getSelectedFilter().pipe(
          switchMap((foodTypeFilter) =>
            username$.pipe(
              map(({ username }) => {
                posts.forEach((post) => {
                  const key = post.photo_url;
                  this.markerMap.put(
                    key,
                    this.markerMap.get(key) || {
                      post,
                      point: undefined,
                      is_food: true,
                      active: false,
                      inactive: false,
                      location: postLocation(post),
                    }
                  );
                });
                console.log("FoodTypeFilter: ");
                console.log(foodTypeFilter);
                return Array.from(this.markerMap.values.values()).filter(
                  (m) =>
                    (!!username || m.is_food) &&
                    !this.failedMarkers.has(m.post.pk) &&
                    (foodTypeFilter == null ||
                      m.post.review.food_types.some(
                        (type) => foodFilters[type.toString()] == foodTypeFilter
                      ))
                );
              })
            )
          )
        )
      )
    );
    this.user = username$.pipe(
      switchMap(({ username, location }) => {
        if (!!!username) {
          return of({ exists: false, user: undefined, location });
        }
        return this.insta
          .getUserDetails(username)
          .pipe(map((user) => ({ user, exists: !!user, location })));
      })
    );
    const generation = this.generation
      .asObservable()
      .pipe(startWith(this.nextGeneration));
    const updatedMarkers = markers$.pipe(
      switchMap((markers) =>
        generation.pipe(
          map((_) => {
            const mapState = this.mapState;
            return { markers, mapState };
          })
        )
      )
    );
    this.clusters = combineLatest([
      this.activeMarker,
      updatedMarkers,
      username$,
    ]).pipe(
      startWith(null),
      pairwise(),
      switchMap(async (pair) => {
        const before = pair[0];
        const after = pair[1];
        if (!after) {
          return;
        }
        const activeMarker = after[0];
        const mapState = after[1];
        const username = after[2].username;
        if (!mapState.mapState) {
          return [];
        }
        const allMarkers = mapState.markers.filter(
          (m) => !username || m.post.username === username
        );
        allMarkers.sort((a, b) =>
          a.active
            ? -1
            : b.active
            ? 1
            : b.post.num_insta_likes - a.post.num_insta_likes
        );
        const zoom = this.mapInstance.getZoom();
        if (!zoom) {
          return [];
        }
        const bounds = this.boundsExpanded;
        const containedInBounds = (ll: LatLngLiteral): boolean => {
          return (
            bounds.getSouth() <= ll.lat &&
            bounds.getNorth() >= ll.lat &&
            bounds.getWest() <= ll.lng &&
            bounds.getEast() >= ll.lng
          );
        };
        if (activeMarker) {
          if (zoom < before[1].mapState.zoom) {
            this.unsetActive();
          } else if (!containedInBounds(activeMarker.location as any)) {
            this.unsetActive();
          }
        }
        const markers = allMarkers.filter((marker) => {
          const keep = containedInBounds(marker.location as any);
          if (!keep) {
            this.markerMap.values.delete(marker.post.pk.toString());
          }
          return keep;
        });
        markers.forEach((marker) => {
          const ll = marker.location;
          marker.point = this.mapInstance.project(ll);
        });
        // tslint:disable-next-line: no-bitwise
        const clusterMap = new Map<Marker, Cluster>();
        const minDistance = zoom < 13 ? 200 : zoom < 15 ? 140 : 100;
        console.log('Zoom: ' + zoom);
        const distanceFn = (a: Marker, b: Marker): number =>
          Math.pow(
            Math.pow(a.point.x - b.point.x, 2) +
              Math.pow(a.point.y - b.point.y, 2),
            0.5
          );
        for (const marker of markers) {
          marker.active = marker === activeMarker;
          marker.inactive = !!activeMarker && !marker.active;
          let keep = true;
          for (const cluster of clusterMap.values()) {
            const clusterMarker = cluster.parent;
            const distance = distanceFn(marker, clusterMarker);
            if (distance < minDistance) {
              keep = false;
              break;
            }
          }
          if (keep) {
            const cluster = new Cluster();
            cluster.parent = marker;
            clusterMap.set(marker, cluster);
          }
        }

        markers
          .filter((marker) => !clusterMap.has(marker))
          .forEach((marker) => {
            const cluster = R.reduce(
              R.minBy((cluster$: Cluster) =>
                distanceFn(cluster$.parent, marker)
              ),
              clusterMap.values().next().value,
              Array.from(clusterMap.values())
            );
            cluster.children.push(marker);
            if (cluster.fanned.length < 2) {
              cluster.fanned.push(marker);
            }
          });
        return Array.from(clusterMap.values()).reverse();
      })
    );
    this.flyerPosition = combineLatest([
      updatedMarkers,
      this.clusters,
      this.user,
    ]).pipe(
      map(([markers, clusters$, user$]) => {
        const username = user$?.user?.username;
        if (!username) {
          return null;
        }
        const allMarkers = markers.markers.filter(
          (m) => !username || m.post.username === username
        );
        if (clusters$.length > 0 || allMarkers.length === 0) {
          return null;
        }
        return allMarkers[0].location;
      })
    );
    this.onResize();
  }

  goToFlyerPosition(location: LatLngLiteral) {
    this.analytics.logEvent("imap-fly-helper");
    this.mapInstance?.setCenter(location);
    this.mapInstance?.setZoom(12);
  }

  mapClick($event: ClickEvent) {
    if (
      !(this.downEvent && $event) ||
      (this.downEvent.screenX === $event.screenX &&
        this.downEvent.screenY === $event.screenY)
    ) {
      this.unsetActive();
    }
    this.downEvent = null;
  }
  mapMouseDown($event: ClickEvent) {
    this.downEvent = $event;
  }

  unsetActive() {
    this.activeMarkerSubject.next(null);
  }

  get location(): LngLat {
    return this.mapInstance?.getCenter();
  }

  mapLoad(mapInstance: MapboxMap) {
    this.mapInstance = mapInstance;
    this.updateGen("map-load");
  }

  zoomChange(_) {
    this.updateGen("zoom-change");
  }

  @HostListener("window:resize", ["$event"])
  onResize(event?) {
    this.updateGen("window");
  }

  markerTap($event, marker: Marker) {
    $event.stopPropagation();
    this.analytics.logEvent("imap-marker-tap", { pk: marker.post.pk });
    this.activeMarkerSubject.next(marker);
    this.mapInstance.panTo(marker.location);
  }

  zoomOut() {
    if (this.mapInstance.getZoom() > this.defaultZoom) {
      this.mapInstance.setZoom(this.defaultZoom);
    }
  }

  goToLink(url: string) {
    this.analytics.logEvent("imap-gotolink", { url });
    window.open(url, "_blank");
  }

  userPhotoTap($event, marker: Marker) {
    $event.stopPropagation();
    this.analytics.logEvent("imap-user-tap", {
      pk: marker.post.pk,
      user: marker.post.username,
    });
    this.unsetActive();
  }

  userPhotoRoute(post: InstagramPost): string[] {
    return !post.username ? null : ["/", post.username];
  }

  clusterTap($event, cluster: Cluster) {
    console.log(cluster.parent.post.insta_post.path);
    console.log(cluster.parent.post.review.food_types);
    $event.stopPropagation();
    this.analytics.logEvent("imap-cluster-tap", { pk: cluster.parent.post.pk });
    if (cluster.parent.active) {
      this.unsetActive();
      return;
    }
    if (cluster.children.length === 0 || this.mapInstance.getZoom() > 20) {
      this.markerTap($event, cluster.parent);
      return;
    }
    const markers = Array.from(cluster.children);
    markers.push(cluster.parent);
    const south = Math.min(...markers.map((m) => m.location.lat));
    const north = Math.max(...markers.map((m) => m.location.lat));
    const west = Math.min(...markers.map((m) => m.location.lng));
    const east = Math.max(...markers.map((m) => m.location.lng));
    const bounds = [
      [west, south],
      [east, north],
    ] as LngLatBoundsLike;
    this.mapInstance.fitBounds(bounds, { padding, maxZoom: 16 });
    this.updateGen("cluster");
  }
  getMapStyle() {
    return mapStyle;
  }
  toggleTaste(tasteMode: boolean) {
    this.analytics.logEvent("imap-toggle", { tasteMode });
    this.router.navigate([tasteMode ? "i" : "t", ""]);
  }
  clickAllFoodies() {
    this.analytics.logEvent("imap-all-foodies", {});
  }

  failedMarker(marker: Marker) {
    this.failedMarkers.add(marker.post.pk);
  }

  async goToMyLocation() {
    navigator.geolocation.getCurrentPosition((p) => {
      const latLng = { lat: p.coords.latitude, lng: p.coords.longitude };
      this.mapInstance.setCenter(latLng);
      this.mapInstance.setZoom(16);
    });
  }
  fullScreenToggle() {
    document.fullscreenElement
      ? document.exitFullscreen()
      : document.documentElement.requestFullscreen();
  }
}

interface ClickEvent {
  screenX: number;
  screenY: number;
}
